<template>
	<div>
		<el-card>
			<div class="global-search-single">
				<div class="single-left">
					<el-button v-if="hasPerm(['admin.store'])" type="primary" @click="handleIncrement">添加人员</el-button>
				</div>
				<div class="single-right">
					<div class="search-item">
						<label class="search-label">人员信息：</label>
						<el-input type="text" v-model="searchInfo.user_name" placeholder="请输入姓名搜索" />
					</div>
					<el-button type="primary" @click="handleSearch">搜索</el-button>
				</div>
			</div>
			<div class="table">
				<el-table :data="tableData" style="width: 100%">
					<el-table-column prop="user_name" label="姓名" width="180">
					</el-table-column>
					<el-table-column prop="mobile" label="手机">
					</el-table-column>
					<el-table-column prop="email" label="邮箱">
					</el-table-column>
					<el-table-column prop="passport" label="账号">
					</el-table-column>
					<el-table-column prop="created_at" label="创建时间" width="200">
					</el-table-column>
					<el-table-column prop="last_login_time" label="最后登录时间" width="200">
					</el-table-column>
					<!-- <el-table-column prop="created_at" label="是否绑定企业微信">
					</el-table-column> -->
					<el-table-column label="操作" align="center">
						<template slot-scope="scope">
							<el-link :underline="false" @click="handleModify(scope.row)"
								v-if="hasPerm(['admin.update'])">编辑</el-link>
							<el-divider direction="vertical"></el-divider>
							<el-link @click="handleDelete(scope.row)" :underline="false"
								v-if="scope.row.is_admin != 1 && hasPerm(['admin.delete'])">删除</el-link>
							<el-divider direction="vertical" v-if="scope.row.is_admin != 1"></el-divider>
							<el-link :underline="false" @click="handleChgPwd(scope.row)"
								v-if="hasPerm(['admin.update.password'])">改密</el-link>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<!-- pagintion -->
			<div class="table_foot d-flex justify-content-between">
				<div class="foot_left"></div>
				<div class="main_pages">
					<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
						:current-page.sync="pageInfo.page" :page-sizes="[10, 20, 30, 40]"
						layout="total,sizes, prev, pager, next, jumper" :pager-count="5" :total="total">
					</el-pagination>
				</div>
			</div>
		</el-card>
	</div>
</template>
  
<script>
import { Message } from 'element-ui';
import common from '../../../common/common';

export default {
	data() {
		return {
			tableData: [],//tableData
			searchInfo: {
				user_name: "",
			},//搜索项
			// page
			pageInfo: {
				page: 1,
				limit: 10
			},
			total: 0,//总条数
			searchObj: {
				search: [],
				order: [
					{
						"key": "created_at",
						"value": "desc"
					}
				]
			},//搜索数据
			password: "",//修改密码
		}
	},
	created() {
		this.getPersonList();
	},
	methods: {
		// 获取人员列表
		async getPersonList() {
			this.$set(this.searchObj, 'search', common.handleSearch(this.searchInfo, 'equal'));
			const { data: { list, total } } = await this.$get(this.$apis.addiTion, { ...this.searchObj, ...this.pageInfo });
			this.$set(this, 'total', total);
			this.$set(this, 'tableData', list);
		},
		// 搜索
		handleSearch() {
			this.pageInfo.page = 1;
			this.getPersonList();
		},
		// 清空事件
		handleClear() {
			this.getPersonList();
		},
		// pageSize
		handleSizeChange(limit) {
			this.$set(this, 'pageInfo', { ...this.pageInfo, limit });
			this.getPersonList();
		},
		// pageNum
		handleCurrentChange(page) {
			this.$set(this, 'pageInfo', { ...this.pageInfo, page });
			this.getPersonList();
		},
		// 添加人员
		handleIncrement() {
			this.$router.push("/setup/management/staffadd");
		},
		// 编辑
		handleModify({ id }) {
			this.$router.push({ path: "/setup/management/staffadd", query: { id } });
		},
		// 删除
		handleDelete({ id, user_name }) {
			this.$confirm(`确定要将员工【${user_name}】永久删除吗?`, "警告", {
				type: 'warning'
			}).then(() => {
				// 删除
				this.$delete(`${this.$apis.deletStaff}${id}`).then(res => {
					if (res.code == 200) {
						Message.success("删除员工成功!");
						// 刷新table
						this.getPersonList();
					} else {
						Message.error(`${res.message}`);
					}
				})
			}).catch((err) => {
				// Message[(() => err == 'cancel' ? 'info' : 'error')()]({
				// 	message: err == 'cancel' ? "已取消删除!" : err
				// });
			})
		},
		// 改密
		handleChgPwd({ id }) {
			this.$router.push({ path: "/setup/management/staffadd", query: { id, type: true } });
		},
	}
}

</script>
  
<style scoped lang="less">
.table {
	padding-top: 20px;

	& /deep/.el-table__header {
		height: 50px !important;
		background: #F9F9FC !important;

		& tr th {
			background: #F9F9FC !important;

			& .cell {
				font-size: 14px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #333333;
				padding: 0 16px;
			}
		}
	}

	& /deep/.el-table__body-wrapper.is-scrolling-none table tbody>tr {
		// height: 76px !important;

		& .cell {
			font-size: 14px;
			font-family: PingFangSC-Regular, PingFang SC;
			font-weight: 400;
			color: #333333;
			padding: 0 16px;
		}
	}

	// & .el-divider--vertical {
	// 	margin: 0 16px
	// }

	& /deep/.el-link {
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: var(--fontColor);
		cursor: pointer;
		height: 20px;
		line-height: 20px;
		// padding: 0 8px;
	}

	/deep/.is-disabled {
		color: #C0C4CC !important;
		cursor: not-allowed !important;
	}
}

.table_foot {
	margin-top: 20px;
}
</style>